<template>
  <h1>Edit Control</h1>
  <div v-if="loadStatus==='ok'" class="row mb-4">

    <div class="col-12 d-lg-none">
      <CNav variant="tabs">
        <CNavItem>
          <CNavLink href="#" :active="isRuleTab" @click="isRuleTab=true">
            Rules & Obligations
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#" :active="!isRuleTab" @click="isRuleTab=false">
            Control Settings
          </CNavLink>
        </CNavItem>
      </CNav>
    </div>

    <div class="col-lg-5 col-xl-4" :class="[isRuleTab ? '' : 'd-none d-lg-block']">
      <ControlRules :rule-items="ruleItems" />
    </div>
    <div class="col" :class="[isRuleTab ? 'd-none d-lg-block' : '']">
      <ControlSettings />
    </div>
  </div>
  <div v-if="loadStatus==='loading'">
    <CSpinner />&nbsp; Loading&hellip;
  </div>
  <div v-if="loadStatus==='error'">
    <CAlert color="danger" class="d-flex align-items-center">
      <CIcon icon="cilWarning" class="flex-shrink-0 me-2" width="24" height="24" />
      Control not found
    </CAlert>
  </div>
</template>

<script>
import ControlSettings from '@/components/ControlSettings.vue';
import ControlRules from '@/components/ControlRules.vue';
import api from '@/services/api';

export default {
  name: 'ControlsEdit',
  components: { ControlSettings, ControlRules },
  data() {
    return {
      loadStatus: 'loading',
      isRuleTab: false,
    };
  },
  computed: {
    ruleItems() {
      return this.$store.state.ctrl.ruleItems;
    },
  },
  methods: {
    async loadCtrl(ctrlId) {
      try {
        let r = await api.ctrl.get(this.$store, ctrlId);
        this.$store.commit('ctrl/load', r.data);
        r = await api.ctrlRule.list(this.$store, ctrlId);
        this.$store.commit('ctrl/setRules', r.data);
        this.loadStatus = 'ok';
      } catch (error) {
        this.loadStatus = 'error';
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
  },
  mounted() {
    this.loadCtrl(this.$route.params.id);
  },
};
</script>
