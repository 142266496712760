<template>
  <div class="bg-dark min-vh-100 d-flex flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol class="limit-width">

          <CCard class="p-2" v-if="step===1">
            <CCardBody>
              <CForm @submit="on_email">
                <h3>Password Reset</h3>
                <p class="text-medium-emphasis">Enter your email address </p>
                <CInputGroup class="mb-4">
                  <CInputGroupText>
                    <CIcon icon="cilUser" />
                  </CInputGroupText>
                  <CFormInput type="email" placeholder="Username (email)" autocomplete="email"
                              v-model="username" required />
                </CInputGroup>
                <CRow>
                  <CCol :xs="6">
                    <router-link to="/login" class="btn btn-outline-primary">
                      <CIcon icon="cilArrowCircleLeft" /> Back
                    </router-link>
                  </CCol>
                  <CCol :xs="6">
                    <CButton type="submit" color="primary" class="float-end"
                             :disabled="is_busy">
                      &nbsp;Next <CIcon icon="cilArrowCircleRight" />
                    </CButton>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>

          <CCard class="p-2" v-if="step===2">
            <CCardBody>
              <CForm @submit="on_set_password">
                <h3>Password Reset</h3>
                <p class="text-medium-emphasis">Enter the reset code that was emailed to you </p>
                <CInputGroup class="mb-4">
                  <CInputGroupText>
                    <CIcon icon="cilDialpad" size="xl" />
                  </CInputGroupText>
                  <CFormInput size="lg" type="text" placeholder="Reset code" v-model="code"
                              required />
                </CInputGroup>
                <p class="text-medium-emphasis">Enter a new password for your account </p>
                <CInputGroup>
                  <CInputGroupText>
                    <CIcon icon="cilLockLocked" />
                  </CInputGroupText>
                  <CFormInput type="password" placeholder="New password" v-model="password"
                              required />
                </CInputGroup>
                <CFormText class="mb-3">Passwords must be at least 8 characters long</CFormText>
                <CInputGroup>
                  <CInputGroupText>
                    <CIcon icon="cilActionRedo" />
                  </CInputGroupText>
                  <CFormInput type="password" placeholder="Repeat password" v-model="password2"
                              required />
                </CInputGroup>
                <CFormText class="mb-4">Enter your new password again</CFormText>
                <CCallout v-if="error_msg!==null" color="danger" class="mb-4">
                  {{ error_msg }}
                </CCallout>
                <CRow>
                  <CCol :xs="6">
                    <CButton type="button" class="btn btn-outline-primary" @click="step--">
                      <CIcon icon="cilArrowCircleLeft" /> Back
                    </CButton>
                  </CCol>
                  <CCol :xs="6">
                    <CButton type="submit" color="primary" class="float-end"
                             :disabled="is_busy">
                      <CIcon icon="cilCheckCircle" /> Set Password
                    </CButton>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>

          <CCard class="p-2" v-if="step===3">
            <CCardBody>
              <h3>Success</h3>
              <p class="text-medium-emphasis">Your password has been reset</p>
              <router-link to="/login" class="btn btn-primary">
                <CIcon icon="cilLockLocked" /> Go to login
              </router-link>
            </CCardBody>
          </CCard>

        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: 'PasswordReset',
  data() {
    return {
      step: 1,
      username: null,
      code: null,
      password: null,
      password2: null,
      is_busy: false,
      error_msg: null,
    };
  },
  methods: {
    on_email(e) {
      e.preventDefault();
      this.step = 2;
    },
    on_set_password(e) {
      e.preventDefault();
      this.error_msg = null;
      if (this.password !== this.password2) {
        this.error_msg = 'Passwords do not match, please re-enter';
      } else if (this.password.length < 8) {
        this.error_msg = 'Password is too short';
      } else {
        this.step = 3;
      }
    },
  },
};
</script>

<style scoped>
  .limit-width {max-width:30rem;}
</style>
