import { createApp } from 'vue';
import CoreuiVue from '@coreui/vue-pro';
import CIcon from '@coreui/icons-vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import icons from '@/assets/iconset';

const app = createApp(App);
app.use(store);
app.use(router);
app.use(CoreuiVue);
app.provide('icons', icons);
app.component('CIcon', CIcon);
app.mount('#app');
