<template>
  <div class="mt-5"></div>
  <h1 class="display-3 text-center">CTRL-ID</h1>
  <p class="text-center">What are you looking for?</p>
  <div class="d-flex justify-content-center mb-5">
    <div class="input-group" style="max-width:30rem;">
      <div class="input-group-text"><CIcon icon="cilSearch" /></div>
      <input type="text" class="form-control" placeholder="Everything..."
             aria-label="Search box" aria-describedby="btn-search">
      <button class="btn btn-outline-secondary" type="button" id="btn-search">Search</button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
};
</script>
