<template>
  <h1>Controls</h1>
  <CCard class="mt-3">
    <CCardHeader class="d-flex align-items-center justify-content-between">
      <div>
        <CIcon icon="cilEqualizer" />
        <strong>&nbsp; Existing Controls</strong>
      </div>
      <router-link to="/control/new" class="btn btn-primary">
        <CIcon icon="cilPlus" />
        &nbsp; New Control&hellip;
      </router-link>
    </CCardHeader>
    <CCardBody>
      <div v-if="isLoading" class="float-end">
        <CSpinner variant="border" color="secondary" size="sm" />
        Loading&hellip;
      </div>
      <CAlert v-if="isEmpty" color="secondary" class="d-flex align-items-center">
        <CIcon icon="cil-info" class="flex-shrink-0 me-2" width="24" height="24" />
        <div>
          The list of controls is empty.
        </div>
      </CAlert>
      <CSmartTable v-if="!isEmpty" cleaner column-filter column-sorter pagination
                   table-filter items-per-page-select
                   :items="items" :columns="columns" :items-per-page="10"
                   :table-props="{striped:true}" :pagination-props="{limit:5}"
                   :sorterValue="{column:'ctrl_id', state:'asc'}">
        <template #ctrl_id="{ item }">
          <td class="td-id">{{ item.ctrl_id }}</td>
        </template>
        <template #edit="{ item }">
          <td class="py-2 td-edit">
            <router-link :to="`/control/${item.ctrl_id}`" class="btn btn-sm btn-outline-primary">
              <CIcon icon="cilPencil" />
              Edit
            </router-link>
          </td>
        </template>
      </CSmartTable>
    </CCardBody>
  </CCard>
</template>

<script>
import api from '@/services/api';

export default {
  name: 'Controls',
  data() {
    return {
      isLoading: true,
      isEmpty: false,
      items: [],
      columns: [
        { key: 'ctrl_id', label: 'ID' },
        { key: 'name' },
        {
          key: 'edit',
          label: '',
          filter: false,
          sorter: false,
        },
      ],
    };
  },
  methods: {
    load() {
      this.isLoading = true;
      api.ctrl.list(this.$store).then((r) => {
        this.isLoading = false;
        this.items = r.data;
        if (this.items.length === 0) this.isEmpty = true;
      }).catch(() => {
        this.isLoading = false;
        // eslint-disable-next-line no-console
        console.log('error');
      });
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style scoped>
.td-id { width: 4rem; }
.td-edit { width: 5rem; }
</style>
