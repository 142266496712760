<template>
  <h1>About Page</h1>
  <p>This is the about page.</p>
</template>

<script>
export default {
  name: 'About',
};
</script>
