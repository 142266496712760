import axios from 'axios';

const api = {
  create_http: (store = null) => {
    if (store) {
      return axios.create({
        headers: { Authorization: `bearer ${store.getters['auth/accessToken']}` },
      });
    }
    return axios.create();
  },
  auth: {
    check: async (store) => {
      if (store.getters['auth/isAuthenticated']) return;
      await store.dispatch('refresh');
    },
    password: async (username, password) => {
      const http = api.create_http();
      const model = new FormData();
      model.append('username', username);
      model.append('password', password);
      return http.post('/api/auth/token/password', model);
    },
    refresh: async () => {
      const http = api.create_http();
      return http.get('/api/auth/token/refresh');
    },
    logout: async () => {
      const http = api.create_http();
      return http.get('/api/auth/logout');
    },
  },
  ctrl: {
    list: async (store) => {
      await api.auth.check(store);
      const http = api.create_http(store);
      return http.get('/api/ctrl');
    },
    get: async (store, ctrlId) => {
      await api.auth.check(store);
      const http = api.create_http(store);
      return http.get(`/api/ctrl/${ctrlId}`);
    },
    create: async (store, ctrl) => {
      await api.auth.check(store);
      const http = api.create_http(store);
      return http.post('/api/ctrl', ctrl);
    },
    update: async (store, ctrl) => {
      await api.auth.check(store);
      const http = api.create_http(store);
      return http.put(`/api/ctrl/${ctrl.ctrl_id}`, ctrl);
    },
  },
  ctrlRule: {
    list: async (store, ctrlId) => {
      await api.auth.check(store);
      const http = api.create_http(store);
      return http.get(`/api/ctrl/${ctrlId}/rule`);
    },
    setList: async (store, ctrlId, ctrlRules) => {
      await api.auth.check(store);
      const http = api.create_http(store);
      return http.put(`/api/ctrl/${ctrlId}/rule`, ctrlRules);
    },
  },
  rule: {
    list: async (store) => {
      await api.auth.check(store);
      const http = api.create_http(store);
      return http.get('/api/rule');
    },
    get: async (store, ruleId) => {
      await api.auth.check(store);
      const http = api.create_http(store);
      return http.get(`/api/rule/${ruleId}`);
    },
  },
  user: {
    get: async (store) => {
      await api.auth.check(store);
      const http = api.create_http(store);
      return http.get('/api/user');
    },
  },
};

export default api;
