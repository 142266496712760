<template>
  <h1>Assessment</h1>
  <p>Client assessment page.</p>
</template>

<script>
export default {
  name: 'Assessment',
};
</script>
