import {
  cilGauge, cilMenu, cilHome, cilLibraryBuilding, cilEqualizer, cilCheckCircle, cilXCircle,
  cilSun, cilMoon, cilUser, cilLockLocked, cilLockUnlocked, cilShieldAlt, cilDialpad,
  cilArrowCircleLeft, cilArrowCircleRight, cilActionRedo, cilSearch, cilCaretLeft, cilCaretBottom,
  cilPlus, cilWarning, cilPencil, cilInfo, cilCog, cibMicrosoft,
} from '@coreui/icons';

const iconsSet = {
  cilGauge,
  cilMenu,
  cilHome,
  cilLibraryBuilding,
  cilEqualizer,
  cilCheckCircle,
  cilXCircle,
  cilSun,
  cilMoon,
  cilUser,
  cilLockLocked,
  cilLockUnlocked,
  cilShieldAlt,
  cilDialpad,
  cilArrowCircleLeft,
  cilArrowCircleRight,
  cilActionRedo,
  cilSearch,
  cilCaretLeft,
  cilCaretBottom,
  cilPlus,
  cilWarning,
  cilPencil,
  cilInfo,
  cilCog,
  cibMicrosoft,
};

export default iconsSet;
