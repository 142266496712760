<template>
  <h1>New Control</h1>
  <div class="row mb-4">
    <div class="col-lg-5 col-xl-4 d-none d-lg-block">
      <ControlRules :rule-items="ruleItems" />
    </div>
    <div class="col">
      <ControlSettings />
    </div>
  </div>
</template>

<script>
import ControlSettings from '@/components/ControlSettings.vue';
import ControlRules from '@/components/ControlRules.vue';

export default {
  name: 'ControlsNew',
  components: { ControlSettings, ControlRules },
  data() {
    return {
    };
  },
  computed: {
    ruleItems() {
      return this.$store.state.ctrl.ruleItems;
    },
  },
  mounted() {
    this.$store.commit('ctrl/new');
  },
};
</script>
