const ctrl = {
  namespaced: true,
  state: {
    ctrl_id: null,
    name: '',
    category: 0,
    sub_category: 0,
    description: '',
    frequency: '',
    governance: '',
    ownership: '',
    attestation: '',
    ruleItems: [],
  },
  getters: {
    ctrlItem(state) {
      return {
        ctrl_id: state.ctrl_id,
        name: state.name,
        category: state.category,
        sub_category: state.sub_category,
        description: state.description,
        frequency: state.frequency,
        governance: state.governance,
        ownership: state.ownership,
        attestation: state.attestation,
      };
    },
    ctrlRules(state) {
      const ctrlRules = [];
      state.ruleItems.forEach((item) => {
        ctrlRules.push({
          ctrl_id: state.ctrl_id,
          rule_id: item,
          highlights: '',
        });
      });
      return ctrlRules;
    },
  },
  mutations: {
    new: (state) => {
      state.ctrl_id = null;
      state.name = '';
      state.category = 0;
      state.sub_category = 0;
      state.description = '';
      state.frequency = '';
      state.governance = '';
      state.ownership = '';
      state.attestation = '';
      state.ruleItems = [];
    },
    load: (state, data) => {
      state.ctrl_id = data.ctrl_id;
      state.name = data.name;
      state.category = data.category;
      state.sub_category = data.sub_category;
      state.description = data.description;
      state.frequency = data.frequency;
      state.governance = data.governance;
      state.ownership = data.ownership;
      state.attestation = data.attestation;
      state.ruleItems = [];
    },
    ctrl_id: (state, value) => { state.ctrl_id = value; },
    name: (state, value) => { state.name = value; },
    category: (state, value) => { state.category = value; },
    sub_category: (state, value) => { state.sub_category = value; },
    description: (state, value) => { state.description = value; },
    frequency: (state, value) => { state.frequency = value; },
    governance: (state, value) => { state.governance = value; },
    ownership: (state, value) => { state.ownership = value; },
    attestation: (state, value) => { state.attestation = value; },
    addRule: (state, ruleId) => {
      if (!state.ruleItems.includes(ruleId)) state.ruleItems.push(ruleId);
    },
    setRules: (state, ctrlRules) => {
      const ruleItems = [];
      ctrlRules.forEach((item) => {
        ruleItems.push(item.rule_id);
      });
      state.ruleItems = ruleItems;
    },
  },
};

export default ctrl;
