const site = {
  namespaced: true,
  state: {
    theme: 'default',
    isSidebar: true,
    isSidebarFoldable: false,
  },
  getters: {
    isDarkTheme: (state) => state.theme === 'dark',
  },
  mutations: {
    setTheme(state, theme) {
      state.theme = theme;
    },
    setSidebar(state, isVisible) {
      state.isSidebar = isVisible;
    },
    toggleSidebar(state) {
      state.isSidebar = !state.isSidebar;
    },
    toggleSidebarFoldable(state) {
      state.isSidebarFoldable = !state.isSidebarFoldable;
    },
  },
};

export default site;
