import { createRouter, createWebHistory } from 'vue-router';
import MainLayout from '@/layouts/defaultLayout.vue';
import Home from '@/views/Home.vue';
import About from '@/views/About.vue';
import Assessment from '@/views/Assessment.vue';
import Controls from '@/views/Controls.vue';
import ControlsEdit from '@/views/ControlsEdit.vue';
import ControlsNew from '@/views/ControlsNew.vue';
import Login from '@/views/Login.vue';
import NotFound from '@/views/NotFound.vue';
import PasswordReset from '@/views/PasswordReset.vue';
import PasswordChange from '@/views/PasswordChange.vue';
import Rules from '@/views/Rules.vue';
import store from '@/store/index';

const redirectIfNotAuthenticated = (to, from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    next();
  } else {
    store.dispatch('auth/refresh').then(() => {
      next();
    }).catch(() => {
      store.commit('auth/setPathAfterLogin', window.location.pathname + window.location.search);
      next({ name: 'Login' });
    });
  }
};

const logout = (to, from, next) => {
  store.dispatch('auth/logout').finally(() => {
    next({ name: 'Login' });
  });
};

const routes = [
  {
    path: '/',
    name: 'Root',
    component: MainLayout,
    redirect: '/home',
    beforeEnter: redirectIfNotAuthenticated,
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home,
      },
      {
        path: '/about',
        name: 'About',
        component: About,
      },
      {
        path: '/assessment',
        name: 'Assessment',
        component: Assessment,
      },
      {
        path: '/control/:id',
        name: 'ControlsEdit',
        component: ControlsEdit,
      },
      {
        path: '/control/new',
        name: 'ControlsNew',
        component: ControlsNew,
      },
      {
        path: '/controls',
        name: 'Controls',
        component: Controls,
      },
      {
        path: '/rules',
        name: 'Rules',
        component: Rules,
      },
      {
        path: '/password-change',
        name: 'PasswordChange',
        component: PasswordChange,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: PasswordReset,
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: logout,
  },
  {
    path: '/:pathMatch(.*)',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
