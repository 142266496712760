<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="toggleSidebar">
        <CIcon icon="cilMenu" size="lg" />
      </CHeaderToggler>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <router-link class="nav-link" to="/about"> About </router-link>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav class="ms-auto me-4">
        <CButtonGroup aria-label="Theme switch">
          <CFormCheck id="btn-light-theme" type="radio" :button="{ color:'secondary' }"
                      name="theme-switch" autocomplete="off" :checked="isDarkTheme===false"
                      @change="toggleTheme">
            <template #label><CIcon icon="cilSun" /></template>
          </CFormCheck>
          <CFormCheck id="btn-dark-theme" type="radio" :button="{ color:'secondary' }"
                      name="theme-switch" autocomplete="off" :checked="isDarkTheme"
                      @change="toggleTheme">
            <template #label><CIcon icon="cilMoon" /></template>
          </CFormCheck>
        </CButtonGroup>
      </CHeaderNav>
      <CHeaderNav class="ms-2">
        <AppHeaderUser />
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
import AppHeaderUser from '@/components/AppHeaderUser.vue';

export default {
  name: 'AppHeader',
  components: { AppHeaderUser },
  computed: {
    isDarkTheme() {
      return this.$store.getters['site/isDarkTheme'];
    },
  },
  methods: {
    toggleTheme() {
      if (this.isDarkTheme) {
        this.$store.commit('site/setTheme', 'default');
        window.document.body.classList.remove('dark-theme');
      } else {
        this.$store.commit('site/setTheme', 'dark');
        window.document.body.classList.add('dark-theme');
      }
    },
    toggleSidebar() {
      this.$store.commit('site/toggleSidebar');
    },
  },
};
</script>

<style scoped>

</style>
