<template>
  <CCard>
    <CCardHeader>
      <div class="d-inline-block pt-2">
        <CIcon icon="cilLibraryBuilding" />&nbsp; Regulations & Obligations
      </div>
    </CCardHeader>
    <CCardBody>
      <ControlRuleItem v-for="item in ruleItems" :key="item" :rule-id="item" />
      <CButton color="secondary" @click="addRule">
        <CIcon icon="cilPlus" />&nbsp; Add Obligation
      </CButton>
    </CCardBody>
  </CCard>
  <CModal size="xl" backdrop="static" :visible="is_modal_visible" @close="addRuleClose">
    <CModalHeader>
      <CModalTitle>Add Rule or Obligation to Control</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <RulesTable @add-rule-item="addRuleItem" />
    </CModalBody>
  </CModal>
</template>

<script>
import ControlRuleItem from '@/components/ControlRuleItem.vue';
import RulesTable from '@/components/RulesTable.vue';

export default {
  name: 'ControlRules',
  components: { ControlRuleItem, RulesTable },
  props: {
    ruleItems: {
      type: Array,
    },
  },
  data() {
    return {
      is_modal_visible: false,
    };
  },
  methods: {
    addRule() {
      this.is_modal_visible = true;
    },
    addRuleClose() {
      this.is_modal_visible = false;
    },
    addRuleItem(item) {
      this.$store.commit('ctrl/addRule', item.rule_id);
    },
  },
};
</script>

<style scoped>

</style>
