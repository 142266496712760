<template>
  <CCard>
    <CCardHeader>
      <CButton v-if="!isSaved" type="submit" form="settings" color="primary" class="float-end"
               :disabled="isSaving">
        <CSpinner v-if="isSaving" size="sm" />
        <CIcon v-else icon="cilCheckCircle" />
        &nbsp; Save
      </CButton>
      <CButton v-if="isSaved" type="button" color="success" class="float-end" disabled>
        <CIcon icon="cilCheckCircle" />
        &nbsp; Saved
      </CButton>
      <div class="d-inline-block pt-2">
        <CIcon icon="cilEqualizer" />&nbsp; Control Settings
      </div>
    </CCardHeader>
    <CCardBody>
      <CAlert v-if="errorText" color="danger" class="d-flex align-items-center"
              dismissible @close="errorText=null">
        <CIcon icon="cilWarning" class="flex-shrink-0 me-2" width="24" height="24" />
        {{ errorText }}
      </CAlert>
      <CForm class="mt-3" id="settings" @submit="onSave">
        <div class="mb-3">
          <CFormLabel for="name">Control Name</CFormLabel>
          <CFormInput type="text" id="name" ref="name" v-model="name" autocomplete="off"
                      required />
          <CFormText>Provide a descriptive name for this new control.</CFormText>
        </div>
        <div class="mb-3">
          <CFormLabel for="category">Category</CFormLabel>
          <CFormSelect id="category" v-model="category" :options="category_opts" />
          <CFormText>Select the risk category for this control</CFormText>
        </div>
        <div class="mb-3">
          <CFormLabel for="sub-category">Sub Category</CFormLabel>
          <CFormSelect id="sub-category" v-model="sub_category"
                       :options="sub_category_opts" />
          <CFormText>Select the risk sub-category for this control, if needed</CFormText>
        </div>
        <div class="mb-3">
          <CFormLabel for="description">Description</CFormLabel>
          <CFormTextarea id="description" v-model="description" rows="4" />
          <CFormText>Describe in detail how this control functions,
            and how it mitigates the obligations</CFormText>
        </div>
        <div class="row mb-3">
          <div class="col">
            <CFormLabel for="frequency">Frequency</CFormLabel>
            <CFormTextarea id="frequency" v-model="frequency" rows="2" />
            <CFormText>How frequently should this control be assessed?</CFormText>
          </div>
          <div class="col">
            <CFormLabel for="governance">Governance</CFormLabel>
            <CFormTextarea id="governance" v-model="governance" rows="2" />
            <CFormText>What are the governance considerations for this control?</CFormText>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <CFormLabel for="ownership">Ownership</CFormLabel>
            <CFormTextarea id="ownership" v-model="ownership" rows="2" />
            <CFormText>Who owns this control, and is it discrete or shared?</CFormText>
          </div>
          <div class="col">
            <CFormLabel for="attestation">Attestation</CFormLabel>
            <CFormTextarea id="attestation" v-model="attestation" rows="2" />
            <CFormText>What attestation requirements will there be for successful
              assessment?</CFormText>
          </div>
        </div>
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
import api from '@/services/api';
import helpers from '@/services/helpers';

export default {
  name: 'ControlEdit',
  data() {
    return {
      isSaving: false,
      isSaved: false,
      errorText: null,
    };
  },
  computed: {
    ctrl_id: {
      get() { return this.$store.state.ctrl.ctrl_id; },
    },
    name: {
      get() { return this.$store.state.ctrl.name; },
      set(value) { this.$store.commit('ctrl/name', value); },
    },
    category: {
      get() { return helpers.optionValue(this.$store.state.ctrl.category); },
      set(value) { this.$store.commit('ctrl/category', value); },
    },
    sub_category: {
      get() { return helpers.optionValue(this.$store.state.ctrl.sub_category); },
      set(value) { this.$store.commit('ctrl/sub_category', value); },
    },
    description: {
      get() { return this.$store.state.ctrl.description; },
      set(value) { this.$store.commit('ctrl/description', value); },
    },
    frequency: {
      get() { return this.$store.state.ctrl.frequency; },
      set(value) { this.$store.commit('ctrl/frequency', value); },
    },
    governance: {
      get() { return this.$store.state.ctrl.governance; },
      set(value) { this.$store.commit('ctrl/governance', value); },
    },
    ownership: {
      get() { return this.$store.state.ctrl.ownership; },
      set(value) { this.$store.commit('ctrl/ownership', value); },
    },
    attestation: {
      get() { return this.$store.state.ctrl.attestation; },
      set(value) { this.$store.commit('ctrl/attestation', value); },
    },
    category_opts() {
      return [
        { value: '0', label: '(none)' },
        { value: '1', label: 'Test category' },
      ];
    },
    sub_category_opts() {
      return [
        { value: '0', label: '(none)' },
        { value: '1', label: 'Test sub-category' },
      ];
    },
  },
  methods: {
    async onSave(e) {
      e.preventDefault();
      this.isSaving = true;
      this.errorText = null;
      const ctrlItem = this.$store.getters['ctrl/ctrlItem'];
      try {
        if (ctrlItem.ctrl_id) {
          await api.ctrl.update(this.$store, ctrlItem);
        } else {
          const r = await api.ctrl.create(this.$store, ctrlItem);
          this.$store.commit('ctrl/ctrl_id', r.data.ctrl_id);
        }
        const ctrlRules = this.$store.getters['ctrl/ctrlRules'];
        await api.ctrlRule.setList(this.$store, this.$store.state.ctrl.ctrl_id, ctrlRules);
        await this.onSaveSuccess();
      } catch (error) {
        this.errorText = 'There was an error when saving the control';
        this.isSaving = false;
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
    async onSaveSuccess() {
      await helpers.wait(1000);
      this.isSaving = false;
      this.isSaved = true;
      await helpers.wait(5000);
      this.isSaved = false;
    },
  },
  mounted() {
    if (!this.$store.state.ctrl.name) helpers.set_focus(this.$refs.name.$el);
  },
};
</script>

<style scoped>

</style>
