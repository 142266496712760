import api from '@/services/api';

const auth = {
  namespaced: true,
  state: {
    accessToken: '',
    expires: Date.now(),
    user: null,
    pathAfterLogin: '/',
  },
  getters: {
    isAuthenticated: (state) => state.accessToken.length > 0 && state.expires > Date.now(),
    pathAfterLogin: (state) => state.pathAfterLogin,
    accessToken: (state) => state.accessToken,
    user: (state) => state.user,
    userName: (state) => state.user.name,
    userInitials: (state) => state.user.initials,
  },
  mutations: {
    setAuth: (state, data) => {
      state.accessToken = data.access_token;
      state.expires = data.expires * 1000; // model.expires is UTC seconds, so convert to ms
      state.user = data.user;
    },
    clearToken: (state) => {
      state.accessToken = '';
      state.expires = Date.now();
    },
    setPathAfterLogin: (state, path) => {
      if (path.length > 2) state.pathAfterLogin = path;
      else state.pathAfterLogin = '/';
    },
  },
  actions: {
    refresh: async ({ commit }) => {
      try {
        const result = await api.auth.refresh();
        commit('setAuth', result.data);
      } catch {
        // refreshing the token did not work, redirect the browser to the login page
        window.location.href = '/login';
      }
    },
    logout: async ({ commit }) => {
      try {
        await api.auth.logout();
        commit('clearToken');
      } catch {
        throw new Error('error during logout');
      }
    },
  },
};

export default auth;
