import { createStore } from 'vuex';
import auth from '@/store/auth';
import site from '@/store/site';
import ctrl from '@/store/ctrl';

const store = createStore({
  modules: {
    auth,
    site,
    ctrl,
  },
});

export default store;
