<template>
  <CContainer>
    <CRow class="justify-content-center">
      <CCol class="limit-width">

        <CCard class="p-2" v-if="step===1">
          <CCardBody>
            <CForm @submit="on_set_password">
              <h3>Change Password</h3>
              <p class="text-medium-emphasis">Enter your current password </p>
              <CInputGroup class="mb-4">
                <CInputGroupText>
                  <CIcon icon="cilLockLocked" />
                </CInputGroupText>
                <CFormInput type="password" placeholder="Current password" v-model="old_password"
                            required />
              </CInputGroup>
              <p class="text-medium-emphasis">Enter a new password for your account </p>
              <CInputGroup>
                <CInputGroupText>
                  <CIcon icon="cilLockLocked" />
                </CInputGroupText>
                <CFormInput type="password" placeholder="New password" v-model="password"
                            required />
              </CInputGroup>
              <CFormText class="mb-3">Passwords must be at least 8 characters long</CFormText>
              <CInputGroup>
                <CInputGroupText>
                  <CIcon icon="cilActionRedo" />
                </CInputGroupText>
                <CFormInput type="password" placeholder="Repeat password" v-model="password2"
                            required />
              </CInputGroup>
              <CFormText class="mb-4">Enter your new password again</CFormText>
              <CCallout v-if="error_msg!==null" color="danger" class="mb-4">
                {{ error_msg }}
              </CCallout>
              <CRow>
                <CCol :xs="6">
                  <router-link to="/home" class="btn btn-outline-danger">
                    <CIcon icon="cilXCircle" />
                    &nbsp;Cancel
                  </router-link>
                </CCol>
                <CCol :xs="6">
                  <CButton type="submit" color="primary" class="float-end"
                           :disabled="is_busy">
                    <CIcon icon="cilCheckCircle" /> Set Password
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>

        <CCard class="p-2" v-if="step===2">
          <CCardBody>
            <h3>Success</h3>
            <p class="text-medium-emphasis">Your password has been changed</p>
            <router-link to="/home" class="btn btn-primary">
              <CIcon icon="cilHome" /> Return home
            </router-link>
          </CCardBody>
        </CCard>

      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
export default {
  name: 'PasswordChange',
  data() {
    return {
      step: 1,
      old_password: null,
      password: null,
      password2: null,
      is_busy: false,
      error_msg: null,
    };
  },
  methods: {
    on_set_password(e) {
      e.preventDefault();
      this.error_msg = null;
      if (this.password !== this.password2) {
        this.error_msg = 'Passwords do not match, please re-enter';
      } else if (this.password.length < 8) {
        this.error_msg = 'Password is too short';
      } else {
        this.step = 2;
      }
    },
  },
};
</script>

<style scoped>
  .limit-width {max-width:30rem;}
</style>
