<template>
    <router-view/>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="scss">
@import 'styles/style';
</style>

<style lang="css">
.bg-highlight { background-color: rgba(0, 0, 21, 0.03); color: rgba(44, 56, 74, 0.95); }
.dark-theme .bg-highlight { background-color: #a7a7a7; }
.dark-theme .form-text { color: rgba(255, 255, 255, 0.38) }
</style>
