<template>
  <div class="bg-dark min-vh-100 d-flex flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol class="limit-width">
          <CCard class="p-2">
            <CCardBody>
              <h3>Login</h3>
              <CNav variant="tabs" role="tablist" class="my-3">
                <CNavItem>
                  <CNavLink :active="tabId === 0" @click="() => { tabId = 0; }">
                    Single Sign On
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink :active="tabId === 1" @click="() => { tabId = 1; }">
                    Email / Password
                  </CNavLink>
                </CNavItem>
              </CNav>
              <CTabContent>
                <CTabPane role="tabpanel" aria-labelledby="sso-tab" :visible="tabId === 0">
                  <p class="text-medium-emphasis">
                    Sign in to CTRL-ID using a registered work account.
                  </p>
                  <CCallout v-if="is_sso_error" color="danger">
                    This account is not registered with CTRL-ID.
                  </CCallout>
                  <div class="mt-5">
                    <CButton component="a" href="/sso/microsoft/login" color="primary"
                             class="float-end">
                      <CIcon icon="cibMicrosoft" />
                      &nbsp; Sign In
                    </CButton>
                    <CButton component="a" href="/sso/microsoft/logout" color="secondary"
                             variant="outline" class="float-end me-4">
                      <CIcon icon="cilXCircle" />
                      &nbsp; Forget current setting
                    </CButton>
                  </div>
                </CTabPane>
                <CTabPane role="tabpanel" aria-labelledby="email-tab" :visible="tabId === 1">
                  <CForm @submit="on_signin">
                    <p class="text-medium-emphasis">
                      Sign in to CTRL-ID using a registered email address and password.
                    </p>
                    <CInputGroup class="mb-3">
                      <CInputGroupText>
                        <CIcon icon="cilUser" />
                      </CInputGroupText>
                      <CFormInput placeholder="Username (email)" autocomplete="email"
                                  v-model="username" ref="username" required />
                    </CInputGroup>
                    <CInputGroup class="mb-4">
                      <CInputGroupText>
                        <CIcon icon="cilLockLocked" />
                      </CInputGroupText>
                      <CFormInput type="password" placeholder="Password"
                                  autocomplete="current-password" v-model="password" required />
                    </CInputGroup>
                    <CCallout v-if="is_error" color="danger">
                      Invalid login credentials, please try again
                    </CCallout>
                    <div>
                      <CButton type="submit" color="primary" class="float-end" :disabled="is_busy">
                        <CIcon icon="cilCheckCircle" />
                        &nbsp;Sign In
                      </CButton>
                      <router-link to="/password-reset"
                                   class="btn btn-outline-primary float-end me-4">
                        <CIcon icon="cilXCircle" />
                        &nbsp;Forgot password?
                      </router-link>
                    </div>
                  </CForm>
                </CTabPane>
              </CTabContent>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import api from '@/services/api';
import helpers from '@/services/helpers';

export default {
  name: 'Login',
  data() {
    return {
      tabId: 0,
      username: null,
      password: null,
      is_busy: false,
      is_error: false,
      is_sso_error: window.location.search.indexOf('err=sso') >= 0,
    };
  },
  methods: {
    on_signin(e) {
      e.preventDefault();
      this.is_busy = true;
      this.is_error = false;
      api.auth.password(this.username, this.password).then((r) => {
        this.$store.commit('auth/setAuth', r.data);
        this.$router.push(this.$store.getters['auth/pathAfterLogin']);
        this.$store.commit('auth/setPathAfterLogin');
      }).catch(() => {
        this.is_error = true;
      }).finally(() => {
        this.is_busy = false;
      });
    },
  },
  mounted() {
    helpers.set_focus(this.$refs.username.$el);
  },
};
</script>

<style scoped>
  .limit-width {max-width:30rem;}
</style>
