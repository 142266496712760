<template>
  <h1>Regulatory Rules</h1>
  <CCard class="mt-3">
    <CCardHeader class="d-flex align-items-center justify-content-between">
      <div class="py-2">
        <CIcon icon="cilLibraryBuilding" class="me-2" />
        <strong>Imported Regulations</strong>
      </div>
    </CCardHeader>
    <CCardBody>
      <div v-if="is_loading" class="float-end">
        <CSpinner variant="border" color="secondary" size="sm" />
        Loading&hellip;
      </div>
      <CSmartTable cleaner column-filter column-sorter pagination table-filter items-per-page-select
                   :items="items" :columns="columns" :items-per-page="10"
                   :table-props="{striped:true}" :pagination-props="{limit:5}">
      </CSmartTable>
    </CCardBody>
  </CCard>
</template>

<script>
import api from '@/services/api';

export default {
  name: 'Rules',
  data() {
    return {
      details: true,
      is_loading: false,
      items: [],
      columns: [
        { key: 'citation' },
        { key: 'title' },
      ],
    };
  },
  methods: {
    load_rules() {
      this.is_loading = true;
      api.rule.list(this.$store).then((r) => {
        this.is_loading = false;
        this.items = r.data;
      }).catch(() => {
        this.is_loading = false;
        // eslint-disable-next-line no-console
        console.log('error');
      });
    },
  },
  mounted() {
    this.load_rules();
  },
};
</script>
