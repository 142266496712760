<template>
  <div v-if="is_loading" class="float-end">
    <CSpinner variant="border" color="secondary" size="sm" />
    Loading&hellip;
  </div>
  <CSmartTable cleaner column-filter column-sorter pagination table-filter items-per-page-select
               :items="items" :columns="columns" :items-per-page="5"
               :table-props="{striped:true}" :pagination-props="{limit:5}">
    <template #show_details="{item, index}">
      <td class="py-2">
        <CButton color="primary" variant="outline" size="sm" @click="showDetails(item, index)">
          <span v-if="this.shownItems.includes(item.rule_id)">Hide</span>
          <span v-else>Show</span>
        </CButton>
      </td>
    </template>
    <template #details="{item}">
      <CCollapse :visible="this.shownItems.includes(item.rule_id)">
        <CCardBody>
          <p>{{ item.content }}</p>
          <CButton color="secondary" v-if="this.linkedItems.includes(item.rule_id)" disabled>
            <CIcon icon="cilPlus" />&nbsp; Added
          </CButton>
          <CButton color="primary" v-else @click="addToControl(item)">
            <CIcon icon="cilPlus" />&nbsp; Add to Control
          </CButton>
        </CCardBody>
      </CCollapse>
    </template>
  </CSmartTable>
</template>

<script>
import api from '@/services/api';

export default {
  name: 'RulesTable',
  emits: ['addRuleItem'],
  data() {
    return {
      is_loading: false,
      items: [],
      shownItems: [],
      linkedItems: [],
      columns: [
        { key: 'citation' },
        { key: 'title' },
        {
          key: 'show_details',
          label: '',
          filter: false,
          sorter: false,
        },
      ],
    };
  },
  computed: {
  },
  methods: {
    load_rules() {
      this.is_loading = true;
      api.rule.list(this.$store).then((r) => {
        this.is_loading = false;
        this.items = r.data;
      }).catch(() => {
        this.is_loading = false;
        // eslint-disable-next-line no-console
        console.log('error - are we logged in?');
      });
    },
    showDetails(item) {
      if (this.shownItems.includes(item.rule_id)) {
        this.shownItems = this.shownItems.filter((_item) => _item !== item.rule_id);
        return;
      }
      this.shownItems.push(item.rule_id);
    },
    addToControl(item) {
      this.$emit('addRuleItem', item);
      this.linkedItems.push(item.rule_id);
    },
  },
  mounted() {
    this.load_rules();
  },
};
</script>

<style scoped>

</style>
