<template>
  <div class="mb-3">
    <div class="card-header bg-highlight border pe-0 position-relative">
      <CButton size="sm" class="float-end stretched-link" @click="is_visible=!is_visible">
        <CIcon icon="cilCaretBottom" v-if="is_visible" />
        <CIcon icon="cilCaretLeft" v-else />
      </CButton>
      <strong>{{ citation }}</strong>
    </div>
    <CCollapse class="pt-3" :visible="is_visible">
      <CButton size="sm" color="secondary" variant="outline" class="mb-2">
        <CIcon icon="cilCog" /> Obligation Settings
      </CButton>
      <p><strong>{{ title }}</strong></p>
      <p>{{ content }}</p>
    </CCollapse>
  </div>
</template>

<script>
import api from '@/services/api';

export default {
  name: 'ControlRuleItem',
  props: {
    ruleId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      rule: null,
      citation: null,
      title: null,
      content: null,
      is_visible: false,
    };
  },
  methods: {
    load_rule(ruleId) {
      api.rule.get(this.$store, ruleId).then((r) => {
        this.rule = r.data;
        this.citation = this.rule.citation;
        this.title = this.rule.title;
        this.content = this.rule.content;
      }).catch(() => {
        // eslint-disable-next-line no-console
        console.log(`Error loading rule ${ruleId}`);
      });
    },
  },
  mounted() {
    this.load_rule(this.ruleId);
  },
};
</script>

<style scoped>

</style>
