<template>
  <div class="mt-5">
    <h1>Error 404</h1>
    <p>The requested page or resource was not found.</p>
    <hr>
    <p>
      <router-link to="/">Go to Home</router-link>
    </p>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>
