const helpers = {
  set_focus: (element) => {
    // Only set field focus on tablets or wider
    if (window.innerWidth > 768) element.focus();
  },
  wait: (ms) => new Promise((resolve) => setTimeout(resolve, ms)),
  optionValue: (value) => {
    if (value != null) return value.toString();
    return null;
  },
};

export default helpers;
