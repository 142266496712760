<template>
  <CSidebar position="fixed" :visible="isVisible" :unfoldable="isFoldable"
            @visible-change="setVisible">
    <CSidebarBrand>
      <CIcon icon="cil-gauge" :height="35" />
      <span class="sidebar-brand-full ms-2 fs-2">CTRL-ID</span>
    </CSidebarBrand>
    <CSidebarNav>
      <li class="nav-title">Navigation</li>
      <CNavItem>
        <router-link to="/home" class="nav-link">
          <CIcon custom-class-name="nav-icon" icon="cilHome" />
          Home
        </router-link>
      </CNavItem>
      <CNavItem>
        <router-link to="/rules" class="nav-link">
          <CIcon custom-class-name="nav-icon" icon="cilLibraryBuilding" />
          Regulations
        </router-link>
      </CNavItem>
      <CNavItem>
        <router-link to="/controls" class="nav-link">
          <CIcon custom-class-name="nav-icon" icon="cilEqualizer" />
          Controls
        </router-link>
      </CNavItem>
      <CNavItem>
        <router-link to="/assessment" class="nav-link">
          <CIcon custom-class-name="nav-icon" icon="cilCheckCircle" />
          Assessment
        </router-link>
      </CNavItem>
    </CSidebarNav>
    <CSidebarToggler class="d-none d-lg-flex" @click="toggleFold"/>
  </CSidebar>
</template>

<script>
export default {
  name: 'AppSidebar',
  computed: {
    isVisible() {
      return this.$store.state.site.isSidebar;
    },
    isFoldable() {
      return this.$store.state.site.isSidebarFoldable;
    },
  },
  methods: {
    toggleFold() {
      this.$store.commit('site/toggleSidebarFoldable');
    },
    setVisible(e) {
      this.$store.commit('site/setSidebar', e);
    },
  },
};
</script>

<style scoped>

</style>
